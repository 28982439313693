import React, { useState, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import ScrollSpyMenu from 'common/src/components/ScrollSpyMenu';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Icon } from 'react-icons-kit';
import { menu } from 'react-icons-kit/feather/menu';
import { x } from 'react-icons-kit/feather/x';
// import { search } from 'react-icons-kit/feather/search';
import Logo from 'reusecore/src/elements/UI/Logo';
import Button from 'reusecore/src/elements/Button';
import Container from 'common/src/components/UI/Container';
import useOnClickOutside from 'common/src/hooks/useOnClickOutside';
import NavbarWrapper, { MenuArea, MobileMenu /*, Search*/ } from './navbar.style';

const NavbarPages = () => {
    const data = useStaticQuery(graphql`
        query {
            appClassicJson {
                navbarPages {
                    logo {
                        publicURL
                    }
                    navMenu {
                        id
                        label
                        path
                        offset
                        staticLink
                    }
                }
            }
        }
    `);
    const { logo, navMenu } = data.appClassicJson.navbarPages;

    const [state, setState] = useState({
        search: '',
        searchToggle: false,
        mobileMenu: false,
    });

    const searchRef = useRef(null);
    useOnClickOutside(searchRef, () => setState({ ...state, searchToggle: false }));

    const toggleHandler = (type) => {
        if (type === 'search') {
            setState({
                ...state,
                search: '',
                searchToggle: !state.searchToggle,
                mobileMenu: false,
            });
        }

        if (type === 'menu') {
            setState({
                ...state,
                mobileMenu: !state.mobileMenu,
            });
        }
    };

    return (
        <NavbarWrapper className='navbar'>
            <Container>
                <Logo notMain={true} className='logo' logoSrc={logo.publicURL} title='App Classic' />

                <MenuArea className={state.searchToggle ? 'active' : ''}>
                    <ScrollSpyMenu className='menu' menuItems={navMenu} notMain={true} offset={-84} />

                    <a href='/#contactFormSection' style={{ marginLeft: '20px' }}>
                        <Button className='trail' title='Entre em Contato' />
                    </a>

                    <Button
                        className='menubar'
                        icon={
                            state.mobileMenu ? (
                                <Icon className='bar' icon={x} />
                            ) : (
                                <Fade>
                                    <Icon className='close' icon={menu} />
                                </Fade>
                            )
                        }
                        color='#0F2137'
                        variant='textButton'
                        onClick={() => toggleHandler('menu')}
                    />
                </MenuArea>
            </Container>

            {/* start mobile menu */}
            <MobileMenu className={`mobile-menu ${state.mobileMenu ? 'active' : ''}`}>
                <Container>
                    <ScrollSpyMenu className='menu' menuItems={navMenu} offset={-84} />
                    <a href='/#contactFormSection' style={{ marginLeft: '20px' }}>
                        <Button className='trail' title='Entre em Contato' />
                    </a>
                </Container>
            </MobileMenu>
            {/* end of mobile menu */}
        </NavbarWrapper>
    );
};

export default NavbarPages;
