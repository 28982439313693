import React, { Fragment } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Fade from 'react-reveal/Fade';
import Text from 'reusecore/src/elements/Text';
import ImageGatsby from 'reusecore/src/elements/ImageGatsby';
import Button from 'reusecore/src/elements/Button';
import { plusCircled } from 'react-icons-kit/ionicons/plusCircled';
import { minusCircled } from 'react-icons-kit/ionicons/minusCircled';
import Heading from 'reusecore/src/elements/Heading';
import Container from 'common/src/components/UI/Container';
import BannerWrapper, { BannerContent, BannerImage, ButtonGroup } from './banner.style';
import {
    Accordion,
    AccordionBody,
    AccordionItem,
    AccordionTitle,
    CloseIcon,
    IconWrapper,
    OpenIcon,
} from 'common/src/components/Accordion';
import { Icon } from 'react-icons-kit';

const Banner = () => {
    return (
        <BannerWrapper>
            <Container>
                <BannerContent>
                    <Fade up>
                        <Heading as='h1' content='Software Securitização' />
                    </Fade>
                    <Fade up delay={100}>
                        <Text>
                            <>
                                O Akrual Securitização é um <i>Software as a Service</i> (SaaS) em ambiente nuvem
                                desenhado para atender a operações estruturadas de crédito, como CRIs, CRAs e FIDCs.
                                Nele é feito o controle completo da operação, passando por todas as etapas envolvidas:
                            </>
                        </Text>
                    </Fade>
                    <Fade up delay={200}>
                        <ul>
                            <li>
                                <Text as={'h4'} content={'Planejamento'} />
                                <Text>
                                    <>Analise de carteira, condições de cessão e critérios de elegibilidade</>
                                </Text>
                            </li>
                            <li>
                                <Text as={'h4'} content={'Modelagem da Curva'} />
                                <Text>
                                    <>
                                        Casamento do fluxo de pagamento dos recebíveis com a curva do saldo devedor (PU
                                        vezes a quantidade de Cotas)
                                    </>
                                </Text>
                            </li>
                            <li>
                                <Text as={'h4'} content={'Escrituração'} />
                                <Text>
                                    <>
                                        Cadastro e formalização de todos os detalhes da Operação. Assim como notificação
                                        de orgãos regulamentadores (B3 / CETIP)
                                    </>
                                </Text>
                            </li>
                            <li>
                                <Text as={'h4'} content={'Controle de Liquidação'} />
                                <Text>
                                    <>Controle das integralizações de Cotas e registro dos cotistas</>
                                </Text>
                            </li>
                            <li>
                                <Text as={'h4'} content={'Covenants e Obrigações'} />
                                <Text>
                                    <>
                                        Controle das equipes para garantir o cumprimento de todas as obrigações assim
                                        como auditar todas as estapas
                                    </>
                                </Text>
                            </li>
                            <li>
                                <Text as={'h4'} content={'Pagamentos Ordinários e Extraordinários'} />
                                <Text>
                                    <>
                                        Cálculo automatizado pelo sistema do PU, Juros e Amortização. Assim, como
                                        cálculo automatizado da cascata de pagamentos para geração de pagamentos
                                        extraordinários.
                                    </>
                                </Text>
                                <Text>
                                    <>
                                        Workflow de aprovação do pagamento, integrando todos os players (Servicer,
                                        Securitizadora, Agente fiduciário, B3/CETIP, Banco liquidante e área financeira)
                                    </>
                                </Text>
                            </li>
                            <li>
                                <Text as={'h4'} content={'Término da Série'} />
                                <Text>
                                    <>
                                        Liquidação antecipada ou não da série na B3/CETIP e armazenamento de todo
                                        histórico da série
                                    </>
                                </Text>
                            </li>
                        </ul>
                    </Fade>
                    <Fade up delay={300}>
                        <Text
                            content='O Sistema permite aos nossos clientes, antecipar tendências de
                            inadimplência,  acompanhar o conjunto de  tarefas e atividades exigidas durante o ciclo
                            de vida da operação, conciliar a posição da carteira de ativos com as Clearings, a fim
                            de manter os riscos de mercado, liquidez e descasamento sob controle, registrar todas
                            as mudanças ocorridas durante o ciclo de vida da operação'
                        />
                    </Fade>
                    <Fade up delay={400}>
                        <Text
                            content='Nós provemos uma solução que atende às necessidades operacionais e estratégicas
                            das Securitizadoras, Agentes Fiduciários, Administradoras, Gestoras e Servicers,
                            provendo informações atualizadas das curvas de pagamento, acesso
                            online dos índices financeiros utilizados, Controle de Fundos, Ratings, e diversas outras funcionalidades para completa
                            gestão das operações de crédito estruturadas.'
                        />
                    </Fade>
                </BannerContent>
            </Container>
        </BannerWrapper>
    );
};

export default Banner;
