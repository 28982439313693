import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
export const accent1 = '#9b8dab';
export const gray5 = '#e0dddd';
const BannerWrapper = styled.div`
    padding-top: 130px;
    min-height: 802px;
    background-color: ${themeGet('colors.light', '#FAFBFF')};
    overflow: hidden;
    @media only screen and (min-width: 1201px) and (max-width: 1440px) {
        min-height: 100vh;
    }
    @media only screen and (max-width: 480px) {
        padding-top: 120px;
    }
    > div.container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: calc(802px - 100px);
        @media only screen and (min-width: 1201px) and (max-width: 1440px) {
            min-height: calc(100vh - 100px);
        }
        @media only screen and (max-width: 480px) {
            flex-wrap: wrap;
        }
    }

    ul {
        list-style: none;
        padding: 0px 20px;
        margin-bottom: 20px;
        background-color: #fff;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        box-shadow: 0 3px 5px 3px rgba(0, 0, 0, 0.16);
        li {
            padding: 8px 0px;
            border-top: 1px solid ${themeGet('colors.secondaryHoverLight', '#FAFBFF')};
            :first-of-type {
                border-top: none;
            }
            p {
                padding-bottom: 0;
                margin-bottom: 3px;
            }
        }
    }
`;

export const BannerContent = styled.div`
    width: 100%;
    @media only screen and (max-width: 991px) {
        padding-right: 20px;
        flex-shrink: 0;
        max-width: 360px;
    }
    @media only screen and (max-width: 480px) {
        padding-right: 0;
        flex-shrink: 0;
        max-width: 360px;
    }

    h1 {
        font-size: 46px;
        line-height: 55px;
        font-weight: 700;
        color: ${themeGet('colors.menu', '#0D233E')};
        margin-bottom: 24px;
        @media only screen and (max-width: 1366px) {
            font-size: 32px;
            line-height: 42px;
            margin-bottom: 20px;
        }
    }

    p {
        color: #4b4e53;
        font-size: 16px;
        line-height: 29px;
    }
`;

export const RatingInfo = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 22px;
    color: ${themeGet('colors.menu', '#0D233E')};

    .rating {
        margin-right: 10px;
        .star {
            color: ${themeGet('colors.yellow', '#FFA740')};
        }
        .star-o {
            color: #e8e8e8;
        }
    }

    img {
        margin-left: 9px;
    }
`;

export const BannerImage = styled.div`
    max-width: 555px;
    width: 100%;
    @media only screen and (max-width: 991px) {
        flex-shrink: 0;
        max-width: 360px;
    }

    flex-shrink: 0;
    align-self: flex-end;
    @media only screen and (max-width: 480px) {
        margin-top: 40px;
        img {
            max-width: 100%;
        }
    }
`;

export const ButtonGroup = styled.div`
    margin-top: 35px;

    .reusecore__button {
        text-transform: inherit;
        border-radius: 5px;
        padding-left: 16px;
        padding-right: 16px;

        &.primary {
            &:hover {
                background-color: ${themeGet('colors.primaryHover', '#3C74FF')};
            }
        }

        &.text {
            .btn-icon {
                svg {
                    width: auto;
                    height: 25px;
                }
            }
            &:hover {
                color: ${themeGet('colors.primaryHover', '#3C74FF')};
            }
        }
    }
`;

export default BannerWrapper;
