import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from 'common/src/theme/appclassic';
import { ResetCSS } from 'common/src/assets/css/style';
import Sticky from 'react-stickynode';
import NavbarPages from '../containers/AppClassic/NavbarPages';
import Banner from '../containers/common/Banner';
import Customer from '../containers/AppClassic/Customer';
import KeyFeatures from '../containers/AppClassic/KeyFeatures';
import AppSlider from '../containers/AppClassic/AppSlider';
import DesignedAndBuilt from '../containers/AppClassic/DesignedAndBuilt';
import Testimonial from '../containers/AppClassic/Testimonial';
import Faq from '../containers/AppClassic/Faq';
import ContactFormSection from '../containers/AppClassic/ContactForm';
import Footer from '../containers/AppClassic/Footer';
import GlobalStyle, { AppWrapper, ContentWrapper } from '../containers/AppClassic/appClassic.style';

import SEO from '../components/seo';
import KeyNumbers from '../containers/AppClassic/KeyNumbers';

export default function () {
    return (
        <ThemeProvider theme={theme}>
            <>
                <SEO title={'Akrual'} />
                <ResetCSS />
                <GlobalStyle />

                <AppWrapper>
                    <Sticky top={0} innerZ={9999} activeClass='sticky-active'>
                        <NavbarPages />
                    </Sticky>
                    <ContentWrapper>
                        <Banner />
                    </ContentWrapper>
                    <Footer notMain={true} />
                </AppWrapper>
            </>
        </ThemeProvider>
    );
}
